.banner {
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: var(--text-color); 
  transition: background-color 0.7s ease, color 0.7s ease; 
  z-index: 0;
}
  
  .contact-banner-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    z-index: 1; /* Ensure images are behind the text */
  }
  
  .contact-banner-text {
    margin-top: 50px;
    position: relative;
    top: -1vh;
    width: 80vw; /* Scale with the viewport */
    max-width: 500px; /* Maximum width */
    margin: auto; /* Center horizontally */
    font-weight: 800; /* Make text bolder */
    text-align: center; /* Center content */
    z-index: 2;
    
  }
  
  .contact-banner-image.light {
    background-image: url('../../assets/images/contactbanner.webp');
  }
  
  .contact-banner-image.dark {
    background-image: url('../../assets/images/contactbannernegative.webp');
  }
