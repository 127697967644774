.banner {
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: var(--text-color); /* Dynamically changes based on theme */
    transition: background-color 0.7s ease; /* Ensures smooth background color transition */
    z-index: 0;
  }
  
  .about-banner-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    z-index: 1; /* Ensure images are behind the text */
  }
  
  .about-banner-text {
    position: relative;
    top: -10vh;
    width: 80vw; /* Scale with the viewport */
    max-width: 800px; /* Maximum width */
    margin: auto; /* Center horizontally */
    font-weight: 800; /* Make text bolder */
    text-align: center; /* Center content */
    z-index: 2;
    
  }

  .about-banner-text.light {
    background-color: rgba(255, 255, 255, 0.555);
    border-radius: 30px;
    border: solid 2px white;
  }
  .about-banner-text.dark {
    background-color: rgba(78, 78, 78, 0.61);
    border-radius: 30px;
    border: solid 2px black;
  }
  
  .about-banner-image.light {
    background-image: url('../../assets/images/aboutbanner.webp');
  }
  
  .about-banner-image.dark {
    background-image: url('../../assets/images/aboutbannernegative2.webp');
  }