.banner {
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: var(--text-color); /* Dynamically changes based on theme */
  transition: background-color 0.7s ease; /* Ensures smooth background color transition */
}

.banner-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: 1; /* Ensure images are behind the text */
}

.banner-text {
  position: relative;
  width: 80vw; /* Scale with the viewport */
  max-width: 500px; /* Maximum width */
  margin: auto; /* Center horizontally */
  font-weight: bold; /* Make text bolder */
  text-align: center; /* Center content */
  z-index: 2;
}

.banner-image.light {
  background-image: url('../../assets/images/banneralt.webp');
}

.banner-image.dark {
  background-image: url('../../assets/images/banneraltnegative.webp');
}
